<template>
  <div class="leadView">
    <!-- <img class="bac" src="~@/assets/img/zige/bac1.png" alt=""> -->
    <div v-if="list && list.length > 0" flex="cross:center main:center">
      <transition name="fade">
        <div class="swiper" @touchend="end" @touchstart="start" @touchmove="move" @mouseover="stop" @mouseleave="play">
          <div class="leftArrow" v-if="list.length > 4">
            <img src="~@/assets/img/zige/arrow2.png" class="leftArrowImg" style="width: 19px" @click="prev" />
          </div>
          <template v-if="list.length > 4">
            <div v-for="(item, index) in list" :key="item.cover" class="leadView_card" @click="chooseItem(item, index)"
              :style="config5[index]">
              <img ref="leadView_card" class="leadView_card_img" :class="index == actives ? 'active' : ''"
                :src="item.certificateImg" alt="" />
              <!-- <div class="img_bg"></div> -->
              <div :class="index == actives ? 'leadView_name actives' : 'leadView_name'">{{ item.certificateName }}
              </div>
            </div>
          </template>
          <template v-else>
            <div v-for="(item, index) in list" :key="item.cover" class="leadView_card" @click="chooseItem(item, index)"
              :style="config4[index]">
              <img ref="leadView_card" class="leadView_card_img" :class="index == actives ? 'active' : ''"
                :src="item.certificateImg" alt="" />
              <div class="img_bg"></div>
              <el-tooltip class="item" effect="dark" :content="item.certificateName" placement="top">
                <!-- <el-button>上边</el-button> -->
                <div class="leadView_name">{{ item.certificateName }}</div>
              </el-tooltip>
            </div>
          </template>

          <div class="rightArrow" v-if="list.length > 4">
            <img src="~@/assets/img/zige/arrow1.png" class="leftArrowImg" style="width: 19px" @click="next" />
          </div>
        </div>
      </transition>
      <p class="swiper_txt">
        <span class="head_num">0{{ currentIndex + 1 }}&ensp; </span>
        <span class="tail_num">/ &ensp; 0{{ list.length }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import { getOrgCertificateList } from "@/api/home";
export default {
  name: "Zt",
  props: {
    list: {
      type: Array,
    },
    listLength: {
      type: Number,
    },
    keyIndex: {
      type: Number,
    },
  },
  data() {
    return {
      loading: true,
      actives: 0,
      currentIndex: 0, // 当前中间imgs数组中index
      centerInfo: "", // 当前中间信息
      startX: "",
      endX: "",
      teaList: [],
      // list: [
      //   {
      //     // ids: 0,
      //     // index: 0,
      //     certificateImg: require("@/assets/img/zige/bac1.png"),
      //     // content:
      //     //   "其实学历对我个人来说作用并不是很大，因为我一直都是在做技术类岗位，在技术类岗位上基本学历的作用不大，但我还是坚持用业余时间把大专本科给考了，这个漫长的时间里最重要一点学会了坚持，然后学会了好多学习的方法和习惯，现在的我刚觉让我一星期不学点新东西或者看一本书我都感觉不自在，学历和知识最大的好处可以提升你的格局，能够增加你的信心给于你自信，当然生活或者工作中肯定是能遇到一些通过你学过的知识来解决的问题的。所以提升学历还是好处多多！加油 ",
      //   },
      //   {
      //     // ids: 1,
      //     // index: 1,
      //     certificateImg: require("@/assets/img/zige/bac2.png"),
      //     // content:
      //     //   "在参加了学历提升和资格证的工作后，才发现学历越来越重要，自己也渐渐的明白之前上学的时候老师说的谆谆教诲，“不要觉得读书不重要什么的，以后你们会感谢自己曾经在高考时候奋斗过的自己”。现在想想非常感谢曾经自己顺利进行了学历提升，顺利完成了学习生涯。",
      //   },
      //   {
      //     // ids: 2,
      //     // index: 2,
      //     certificateImg: require("@/assets/img/zige/bac3.png"),
      //     // content:
      //     //   "我的许多同学毕业后在大企业工作了多年，工作认真，能力突出，却没有得到相应的晋升。为什么？那是因为教育程度低。如果达不到公司要求的学历，就没有升职的机会。 ",
      //   },
      //   {
      //     // ids: 3,
      //     // index: 3,
      //     certificateImg: require("@/assets/img/zige/bac4.png"),
      //     // content:
      //     //   "主要是我以后想要靠公务员，学历只有专科，参加了专升本，很多在职人员或刚入社会的大学生都选择这条路，但是他有非常严格的学历要求，你有文凭才有资格报名，现在当然也上岸成功啦~ ",
      //   },
      //   {
      //     // ids: 4,
      //     // index: 4,
      //     certificateImg: require("@/assets/img/zige/bac5.png"),
      //     // content:
      //     //   "我考研是为了以后更好的就业，而不是为了躲避就业压力，考研这条路，没你想象中那么容易，所以如果确定了，就走下去，也永远不要在想起这个问题，这条路上不需要纠结，因为你纠结的那一天，你的对手们正在拼命的刷着题，背着单词~ ",
      //   },
      //   {
      //     // ids: 5,
      //     // index: 5,
      //     certificateImg: require("@/assets/img/zige/bac2.png"),
      //     // content:
      //     //   "上升一个平台后，我才会发现，我得到的不止是一张文凭。而是更大的平台、更广阔的视野、更包容的环境，最重要的是考研中后我有了重新认识自己的机会，无限未知的前途等着我，哈哈 ",
      //   },
      //   {
      //     // ids: 6,
      //     // index: 6,
      //     certificateImg: require("@/assets/img/zige/bac3.png"),
      //     // content:
      //     // "现在多单位都是要求本科起步，对于我这个来说，专升本是提升学历的必经之路。学历不同，升职加薪也是不一样哦，当然，现在已经顺利进入目标企业拉~ ",
      //   },
      //   {
      //     // ids: 7,
      //     // index: 7,
      //     certificateImg: require("@/assets/img/zige/bac4.png"),
      //     // content:
      //     //   "我从小就想当一名教师，报考幼儿和小学层级教师资格证的考生须具备大专及以上的学历，而报考中学层级教师资格证的考生则必须具备本科及以上的学历，现在专升本已经上岸了，接下来就是要考中学教师资格，加油吧！ ",
      //   },
      // ],
      previous: 0,
      config4: [
        {
          id: "-A",
          position: "absolute",
          width: "266px",
          height: "150px",
          top: "0",
          bottom: "0",
          margin: "auto 0",
          left: "0px",
          opacity: 1,
          zIndex: 1,
          // transition: '2.4s',
          backgroundColor: "rgba(4,57,46,80%)",
        },
        {
          id: "A",
          position: "absolute",
          width: "266px",
          height: "150px",
          top: "0",
          bottom: "0",
          margin: "auto 0",
          left: "325px",
          opacity: 1,
          zIndex: 1,
          // transition: '2.4s',
          // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.06)',
          borderradius: "0px 0px 0px 0px",
          background: "red",
        },
        {
          id: "center",
          position: "absolute",
          width: "266px",
          height: "150px",
          top: "0",
          bottom: "0",
          margin: "auto 0",
          right: "325px",
          // marginLeft: '-22.5%',
          opacity: 1,
          zIndex: 1,
          // transition: '2.4s',
          background: "#00AB8C",
        },
        {
          id: "E",
          position: "absolute",
          width: "266px",
          height: "150px",
          top: "0",
          bottom: "0",
          margin: "auto 0",
          right: "40px",
          opacity: 1,
          zIndex: 1,
          background: "yellow",
          // transition: '2.4s'
        },
        {
          id: "E+",
          position: "absolute",
          width: "266px",
          height: "150px",
          bottom: "0",
          margin: "auto 0",
          top: "4px",
          right: "-266px",
          opacity: 0,
          zIndex: 0,
          // transition: '2.4s',
          background: "green",
        },
      ],
      config5: [
        {
          id: "-A",
          position: "absolute",
          width: "266px",
          height: "150px",
          top: "0",
          bottom: "0",
          margin: "auto 0",
          left: "40px",
          opacity: 1,
          zIndex: 1,
          // transition: '2.4s',
          backgroundColor: "rgba(4,57,46,80%)",
        },
        {
          id: "A",
          position: "absolute",
          width: "266px",
          height: "150px",
          top: "0",
          bottom: "0",
          margin: "auto 0",
          left: "325px",
          opacity: 1,
          zIndex: 1,
          // transition: '2.4s',
          // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.06)',
          borderradius: "0px 0px 0px 0px",
          background: "red",
        },
        {
          id: "center",
          position: "absolute",
          width: "266px",
          height: "150px",
          top: "0",
          bottom: "0",
          margin: "auto 0",
          right: "325px",
          // marginLeft: '-22.5%',
          opacity: 1,
          zIndex: 1,
          // transition: '2.4s',
          background: "#00AB8C",
        },
        {
          id: "E",
          position: "absolute",
          width: "266px",
          height: "150px",
          top: "0",
          bottom: "0",
          margin: "auto 0",
          right: "40px",
          opacity: 1,
          zIndex: 1,
          background: "yellow",
          // transition: '2.4s'
        },
        {
          id: "E+",
          position: "absolute",
          width: "266px",
          height: "150px",
          bottom: "0",
          margin: "auto 0",
          top: "4px",
          right: "-266px",
          opacity: 0,
          zIndex: 0,
          // transition: '2.4s',
          background: "green",
        },
      ],
    };
  },
  mounted() {
    this.play();
    this.chooseItem(this.list[0], 0);
  },
  watch: {
    'keyIndex': function (newVal, oldVal) {
      this.chooseItem(newVal, newVal);


    }
  },
  created() {
    this.getData();
    this.centerCard(); // 获取中间卡片信息
    this.addCardStyle(); // 加入样式位置的index
    this.selectPcPageList(); // 加入样式位置的index

    this.chooseItem(this.keyIndex, this.keyIndex)
    console.log(this.actives, ' this.actives');

  },
  methods: {
    // 获取数据
    async getData() {
      this.$nextTick(() => {
        this.loading = false;
      });
    },

    async selectPcPageList() { },
    // 滑动上一个
    prev() {
      this.currentIndex = this.currentIndex - 1;
      this.config5.push(this.config5.shift());
      if (this.currentIndex < 0) {
        this.currentIndex = this.list.length - 1;
      }
      this.chooseItem(this.list[this.currentIndex], this.currentIndex);
      this.centerCard();
      this.centerIndex("prev");
    },
    // 滑动下一个
    next() {
      this.currentIndex = this.currentIndex + 1;
      this.config5.unshift(this.config5.pop());
      if (this.currentIndex > this.list.length - 1) {
        this.currentIndex = 0;
      }
      this.chooseItem(this.list[this.currentIndex], this.currentIndex);
      this.centerCard();
      this.centerIndex("next");
    },
    // 开始移动端滑动屏幕
    start(event) {
      this.startX = event.changedTouches[0].clientX;
      this.startY = event.changedTouches[0].clientY;
    },
    // 连续滑动
    move(event) {
      this.endY = event.changedTouches[0].clientY;
      this.endX = event.changedTouches[0].clientX;
      this.stopDefault(event);
      // 如果是滑动，注解（223行到231行）这段。如果是连续滑动，放开（223行到231行）注解
      this.interval = this.endX - this.startX;
      if (this.interval > 40) {
        this.startX = this.endX;
        this.prev();
      }
      if (this.interval < -40) {
        this.startX = this.endX;
        this.next();
      }
    },
    // 滑动
    end(event) {
      // 如果是滑动，放开（236行到238行）的注解。如果是连续滑动，注解（236行到238行）
      // this.endY = event.changedTouches[0].clientY;
      // this.endX = event.changedTouches[0].clientX;
      // this.formatSwiper();
    },
    formatSwiper() {
      if (this.startX > this.endX) {
        if (this.startX > this.endX + 40) {
          this.next();
        }
      } else {
        if (this.endX > this.startX + 40) {
          this.prev();
        }
      }
    },
    // 阻止touchmove的横向默认事件（ios快捷操作会关闭页面）
    stopDefault(event) {
      const differenceY = this.endY - this.startY;
      const differenceX = this.endX - this.startX;
      if (Math.abs(differenceX) > Math.abs(differenceY)) {
        event.preventDefault();
      }
    },
    // 当前imgs在位置上的index（并非img数组的index）
    centerIndex(val) {
      if (val == "prev") {
        for (const val of this.list) {
          if (val.index == this.list.length - 1) {
            val.index = 0;
          } else {
            val.index = val.index + 1;
          }
        }
      } else {
        for (const val of this.list) {
          if (val.index == 0) {
            val.index = this.list.length - 1;
          } else {
            val.index = val.index - 1;
          }
        }
      }
    },
    // 点击
    chooseItem(item, index, status) {
      this.currentIndex = index;
      this.actives = index;
      // const cycles = item.index;
      this.$emit("isOk", item, index);
    },
    // 计算中间卡片信息
    centerCard() {
      this.centerInfo = this.list[this.currentIndex];
      // if (this.centerInfo.ids == 0) {
      //   this.active = this.list.length - 1
      // } else {
      //   this.active = this.centerInfo.ids - 1
      // }

      this.$emit("centerInfo", this.centerInfo);
    },

    addCardStyle() {
      if (this.list.length > 5) {
        const addtime = this.list.length - 5;
        for (let i = 0; i < addtime; i++) {
          this.config5.push({
            id: "center",
            position: "absolute",
            width: "266px",
            height: "150px",
            top: "0px",
            left: `-${(i + 1) * 266}px`,
            // marginLeft: '-22.5%',
            opacity: 0,
            transition: "0s",
          });
        }
      }
    },
    play() {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = window.setInterval(() => {
        this.next();
      }, 3000000);
    },
    stop() {
      window.clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style lang="less" scoped>
.swiper {
  margin: 0 auto;
  width: 1200px;
  height: 158px;
  position: relative;
  overflow: hidden;

  div {
    opacity: 1;
  }

  .leftArrow {
    opacity: 1;
    position: absolute;
    left: 0;
    bottom: 57px;
    cursor: pointer;
    z-index: 999;
  }

  .rightArrow {
    opacity: 1;
    position: absolute;
    right: 0px;
    top: 57px;
    cursor: pointer;
    z-index: 999;
  }
}

.leadView {
  width: 1200px;
  margin: auto;
  // height: 631px;
  // background: #fff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  .bac {
    width: 500px;
    height: 657px;
    top: 1400px;
    right: 0px;
    position: absolute;
  }

  .leadView_title {
    margin: 0px auto 63px;
    width: 192px;
    height: 76px;
    background-image: url("~@/assets/img/zige/bac1.jpg");
    background-size: 192px 76px;
  }

  .swiper_txt {
    margin-top: 24px !important;
    margin: 0 auto;
    color: #fff;
    height: 20px;
    width: 120px;
    // background-color: #00ab8c;
    z-index: 99;

    .head_num {
      font-size: 20px;
    }

    .tail_num {
      font-size: 14px;
    }
  }

  .leadView_card {
    // shape-rendering: ;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // padding: 26px 36px 24px 31px;
    // box-shadow: 0px 0px 20px 1px rgba(57, 190, 166, 0.16);
    opacity: 1;
    width: 100%;
    height: 150px;
    //  background: #00AB8C;
    border-radius: 0px 0px 0px 0px;
    position: relative;

    .leadView_card_img {
      width: 266px;
      height: 150px;
      object-fit: cover;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      overflow: hidden;

      // border: 6px solid #fff !important;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .img_bg {
      width: 266px;
      height: 150px;
      background: url("~@/assets/img/zige/s_bg.png") no-repeat;
      position: absolute;
      top: 0;
      left: 0;
    }

    .leadView_name {
      width: 100%;
      padding-left: 14px;
      height: 60px;
      line-height: 60px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      z-index: 5;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);


    }

    // .center {
    //   margin-left: 353px;
    //   .leadView_card_content_title {
    //     margin-top: 55px !important;
    //     color: #fff !important;
    //     span {
    //       width: 84px;
    //       height: 20px;
    //       font-size: 14px;
    //       font-family: PingFang SC-Regular, PingFang SC;
    //       font-weight: 400;
    //       color: #ffffff !important;
    //     }
    //   }
    //   .leadView_card_content_text {
    //     width: 296px !important;
    //     color: #fff !important;
    //   }
    //   .leadView_card_content_major {
    //     color: #fff !important;
    //     opacity: 1;
    //   }
    //   .leadView_card_content_block {
    //     background: #fff !important;
    //     opacity: 1;
    //   }
    // }
    .leadView_card_content {
      width: 383px;
      height: 100%;
      opacity: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .leadView_card_content_img {
        width: 48px;
        height: 48px !important;
        margin-top: 34px;
        border-radius: 50%;
        background: #333;
        margin-bottom: 20px;
        opacity: 1;
        overflow: hidden;

        img {
          width: 100%;
          // height: 100%;
        }
      }

      .leadView_card_content_title {
        width: 100%;
        height: 28px;
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        margin-top: 10px;
        color: #333333;
        opacity: 1;

        span {
          height: 20px;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          opacity: 1;
        }
      }

      .leadView_card_content_major {
        width: 240px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        opacity: 1;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        margin-bottom: 24px;
      }

      .leadView_card_content_block {
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-top: 33px;
        margin-right: 4px;
        margin-bottom: 32px;
        background: #00ab8c;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }

      .leadView_card_content_text {
        width: 177px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        opacity: 1;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }

      .leadView_card_content_btn {
        position: absolute;
        width: 226px;
        height: 48px;
        // box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.06);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        bottom: 58px;
        background: #00ab8c;
        border: 1px solid #ffffff;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .active {
    // top: 0;
    // bottom: 0;
    // margin: auto 0;
    border: 4px solid #fba627 !important;
    opacity: 1;


  }

  .actives {
    width: 258px !important;
    bottom: 4px !important;
    left: 4px !important;
  }
}
</style>
