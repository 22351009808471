<template>
  <div ref="certificateView" class="certificateView">
    <navigation3 :menu-list="menuList" :active-index="(activeIndex + 1).toString()" :is-index="false"
      @selectChange="selectChange" class="navigation3" />
    <div class="tips_box">
      <div ref="popuptel" class="popuptel2">
        {{ domainInfo.mobile }}
        <div class="triangle tel" />
      </div>
      <div ref="popup1" class="popuptel1">
        <el-image class="img" :src="domainInfo.wechatMpName" v-if="domainInfo.wechatMpName"></el-image>
        <p class="img_p" v-else>暂无图片</p>
        <div class="triangle tel" />
      </div>
      <div ref="popup" class="popuptel">
        {{ domainInfo.mobile }}
        <div class="triangle tel" />
      </div>
      <div v-for="item in 3" :key="item" class="tips_box_item">
        <img :src="require(`@/assets/img/zige/r${item}.png`)" alt="" @mouseenter="popup(item)"
          @mouseleave="popdown(item)" />
      </div>
    </div>
    <div ref="followingBox" style="min-height: 100vh">
      <div v-for="(item, index) in list" v-show="index == activeIndex" :key="index" ref="following" class="following">
        <div ref="following_item" class="following_item">
          <div class="following_item_blur" :class="`blur${index}`" />
          <div ref="following_item_active" class="following_item_active">
            <div class="following_item_title">{{ item.certificateName }}</div>

            <div class="following_item_content" v-html="item.introduce" />
            <div class="following_item_line" />
            <div class="following_item_tips dis_flex_strat">
              <div class="item_tips">发证单位：</div>
              <div v-for="(items, indexs) in item.unitName" :key="indexs" class="unit">
                {{ items + "颁发" }}
              </div>
            </div>
            <div class="following_item_btn_box">
              <el-button v-if="item.specialUrl" class="following_item_btn" @click="goNextPage(item)">
                <span class="btn_title">查看详情</span>
                <el-image :src="require('@/assets/img/zige/right.png')" class="btn_img" alt="" />
              </el-button>
              <el-button v-else class="following_item_btn" @click="
                goNext(
                  `/agent/consulting?classifId=${item.id}&certificateName=${item.certificateName}`
                )
                ">
                <span class="btn_title">查看详情</span>
                <el-image :src="require('@/assets/img/zige/right.png')" class="btn_img" alt="" />
              </el-button>
              <el-button class="following_item_btn write" @click="
                goNext(
                  `/agent/consulting?classifId=${item.id}&certificateName=${item.certificateName}`
                )
                ">
                <span class="btn_title">报名咨询</span>
                <el-image :src="require('@/assets/img/zige/right.png')" class="btn_img" alt="" />
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <swiper v-if="list.length > 0 && isShow" :keyIndex="activeIndex" :list-length="listLength" class="swipers"
        :list="imgs" @isOk="isOk" />
    </div>
  </div>
</template>
<script>
import { getOrgCertificateByName, getPcList } from "@/api/home";
import navigation3 from "./components/navigation3.vue";
import { getDomain } from "@/api/cookies";
import swiper from "@/views/agent/certificate/swiper.vue";

export default {
  components: { navigation3, swiper },
  data() {
    return {
      flag: true,
      isShow: true,
      index: 0,
      domainInfo: {},
      classifId: 0,
      activeIndex: 0,
      list: [],
      listLength: 0,
      imgs: [],
      menuList: [],
    };
  },
  async created() {
    this.classifId = this.$route.query.id ? Number(this.$route.query.id) : 0;
    if (this.classifId) {
      this.menuList.forEach((e, i) => {
        if (e.id == this.classifId) {
          this.activeIndex = i
          console.log(this.activeIndex,'this.activeIndex');
        }
      });
    }
    const params = {
      type: 2,
      status: 1,
    };
    const res = await getPcList({ status: 1 });
    if (res.code == 0) {
      this.menuList = res.data;
    }
    this.getList();
    this.domainInfo = getDomain();
  },
  methods: {
    popup(item) {
      if (item == 3) {
        this.$refs.popup.style.display = "block";
      }
      if (item == 2) {
        this.$refs.popup1.style.display = "block";
      }
      if (item == 1) {
        this.$refs.popuptel.style.display = "block";
      }
    },
    popdown(item) {
      if (item == 3) {
        this.$refs.popup.style.display = "none";
      }
      if (item == 2) {
        this.$refs.popup1.style.display = "none";
      }
      if (item == 1) {
        this.$refs.popuptel.style.display = "none";
      }
    },
    isOk(val, ind) {

      this.activeIndex = ind;
      this.$refs.certificateView.style.backgroundImage = `url(${val.certificateImg}`;

      if (ind >= 4) {
        ind = ind % 4;
      }
      this.$refs.followingBox.classList = `bac${ind}`;

      this.$forceUpdate()
    },
    async getList() {
      const params = {
        pageSize: 999,
        pageNum: 1,
        classifId: this.classifId,
      };
      const res = await getPcList({ status: 1 });
      res.data.forEach((element) => {
        element.unitName = element.unitName.split(",");
      });
      this.list = res.data;
      this.listLength = res.data.length;
      this.imgs = [];
      this.imgs = this.list.map((element, index) => {
        return Object.assign({ ids: index, index: index }, element);
      });
    },
    // setActiveItem(e) {
    //   this.activeIndex = e;
    //   this.$refs.certificateView.style.backgroundImage = `url(${val.certificateImg}`;
    //   if (ind >= 4) {
    //     ind = ind % 4;
    //   }
    //   this.$refs.followingBox.classList = `bac${ind}`;
    //   this.$forceUpdate();
    // },
    selectChange(val, vals) {
      // this.isShow = false

      this.activeIndex = parseInt(val);
      this.$refs.certificateView.style.backgroundImage = `url(${vals.certificateImg}`;

      if (parseInt(val) >= 4) {
        val = parseInt(val) % 4;
      }
      this.$refs.followingBox.classList = `bac${parseInt(val)}`;
      // this.$nextTick(() => {
      //   this.isShow = true
      // })

      this.$forceUpdate();

    },
    goNextPage(item) {
      window.open(item.specialUrl, '_blank');
    }
  },
};
</script>
<style lang="less" scoped>
.navigation3 {
  position: fixed;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0);
}

.certificateView {
  position: relative;
  height: 100vh;
  background-size: cover;

  .swipers {
    margin-top: 80px;
  }

  .swipers1 {
    margin-top: 80px;
    // margin-left: -50px;
  }

  .bac3 {
    background: rgba(54, 11, 11, 0.7);
  }

  .bac0 {
    background: rgba(4, 57, 46, 0.7);
  }

  .bac1 {
    background: rgba(30, 40, 81, 0.7);
  }

  .bac2 {
    background: rgba(30, 54, 81, 0.7);
  }

  .following_item {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    background-size: cover;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;

    .following_item_blur {
      height: 100%;
      background-image: linear-gradient(to bottom right,
          rgba(30, 54, 81, 0.7),
          rgba(30, 54, 81, 0.6));
    }

    .following_item_active {
      padding-top: 246px;
      margin: 0 auto;
      background-size: cover;
      width: 1200px;
      z-index: 2;

      .following_item_letter {
        position: absolute;
        width: 599px;
        opacity: 0.2;
        z-index: 999px;
        height: 66px;
        top: 260px;
        font-size: 50px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 55px;
      }

      .following_item_title {
        height: 66px;
        font-size: 50px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 5px;
        width: 1200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .following_item_line {
        width: 55px;
        height: 0px;
        border: 1px solid #ffffff;
        opacity: 1;
        margin-top: 40px;
      }

      .following_item_content {
        width: 1100px;
        height: 64px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 36px;
        letter-spacing: 2px;
        margin-top: 30px;
        height: 64px !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical !important;
      }

      .following_item_tips {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 43px;
        margin-top: 38px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;

        .item_tips {
          width: 80px;
          height: 21px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          margin-right: 6px;
        }

        .unit {
          padding: 0 8px;
          line-height: 28px;
          margin-right: 10px;
          height: 28px;
          background: rgba(255, 255, 255, 0.17);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #ffffff;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .following_item_btn {
        margin-right: 30px;
        width: 160px;
        height: 54px;
        background: #fba627;
        border-radius: 29px;
        opacity: 1;
        border: none;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 0;

        .btn_title {
          font-size: 16px;
          margin-right: 11px;
        }

        .btn_img {
          vertical-align: middle;
          display: inline-block;
          width: 15.6px;
          height: 13px;
        }
      }

      .write {
        background: #fff;
      }
    }
  }

  .timeLine_box {
    position: fixed;
    z-index: 999;
    left: 51px;
    top: 345px;

    .timeLine_box_item {
      position: relative;
      width: 12px;
      height: 12px;
      background: #ffffff;
      opacity: 0.5;
      margin: 20px 0;
      border-radius: 50%;

      .timeLine_box_item_line {
        top: 43px;
        left: 15px;
        position: absolute;
        width: 2px;
        height: 30px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }

    .active {
      width: 34px;
      margin-bottom: 63px;
      height: 34px;
      opacity: 1;
      transform: translateX(-10px);
      background: none;
      border: 1px solid #ffffff;
    }

    .last {
      margin-top: 63px;

      .timeLine_box_item_line {
        top: -43px;
      }
    }
  }

  .tips_box {
    position: fixed;
    z-index: 999;
    right: 51px;
    top: 345px;

    .popup {
      position: absolute;
      display: none;
      right: 68px;
      top: 63px;
      width: 150px;
      height: 150px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
    }

    .popuptel {
      position: absolute;
      display: none;
      right: 68px;
      top: 135px;
      width: 150px;
      height: 54px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      line-height: 54px;
      padding-left: 17px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .popuptel1 {
      position: absolute;
      display: none;
      right: 68px;
      top: 65px;
      width: 150px;
      height: 150px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      line-height: 54px;
      padding-left: 17px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 130px;
        height: 130px;
        margin-top: -65px;
        margin-left: -65px;
      }

      .img_p {
        text-align: center;
        line-height: 150px;
        margin-left: -5px;
      }
    }

    .popuptel2 {
      position: absolute;
      display: none;
      right: 68px;
      top: 0px;
      width: 150px;
      height: 54px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      line-height: 54px;
      padding-left: 17px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .triangle {
      position: absolute;
      right: -11px;
      top: 11px;
      width: 0;
      height: 0;
      border-left: 14px solid #fff;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }

    .tel {
      top: 20px;
    }

    .tips_box_item {
      width: 48px;
      height: 48px;
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      margin-bottom: 20px;
      border: 1px solid #ffffff;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<style>
.el-tooltip__popper {
  font-size: 16px !important;
}
</style>
